.footer1-wrapper {
  background: @template-bg-color;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: @template-footer-text-color;
  .footer1 {
    .home-page {
      padding: 64px 24px 80px;
    }
  }
  .block {
    padding: 0 32px;
    .logo {
      max-width: 180px;
    }
    .slogan {
      font-size: 12px;
      margin-top: -20px;
    }
    >h2 {
      margin-bottom: 24px;
      color: @template-text-color;
    }
    a {
      color: @template-footer-text-color;
      margin-bottom: 12px;
      float: left;
      clear: both;
      &:hover {
        color: @primary-color;
      }
    }
  }
  .copyright-wrapper {
    width: 100%;
    border-top: 1px solid fade(@line-color, 10);
    .home-page {
      padding: 0 0px;
      overflow: hidden;
    }
    .copyright {
      height: 100px;
      text-align: center;
      line-height: 40px;
    }
  }
}

@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
    &-wrapper {
      .footer1 {
        .home-page {
          padding: 64px 24px 32px;
        }
      }
    }
    .logo {
      margin: 0 auto 24px;
    }
    .block {
      text-align: center;
      margin-bottom: 32px;
      padding: 0;
    }
    >ul {
      width: 90%;
      margin: 20px auto 0;
      padding: 10px 0;
      >li {
        width: 100%;
        h2 {
          margin-bottom: 10px;
        }
        li {
          display: inline-block;
          margin-right: 10px;
        }
      }
    }
    .copyright {
      &-wrapper {
        .home-page {
          padding: 0;
          .copyright {
            font-size: 12px;
          }
        }
      }

      span {
        width: 90%;
      }
    }
  }
}
