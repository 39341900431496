body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.content13-wrapper {
  height: 480px;
  min-height: 380px;
  background: url("https://api.highwho.com/static/homepage/img/banner.png") no-repeat bottom;
  background-size: cover;
  background-size: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
}
.content13-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content13-wrapper .title-content {
  line-height: 32px;
}
@media screen and (max-width: 767px) {
  .content13-wrapper {
    padding-bottom: 0;
  }
}
.content1-wrapper {
  height: 360px;
}
.content1-wrapper .content1 {
  height: 100%;
  padding: 0 24px;
}
.content1-wrapper .content1-img {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content1-wrapper .content1-img span {
  display: block;
  width: 250px;
}
.content1-wrapper .content1-img span img {
  display: block;
}
.content1-wrapper .content1-text {
  padding: 0 32px;
  height: 100%;
}
.content1-wrapper .content1-text .content1-content,
.content1-wrapper .content1-text .content1-title {
  position: relative !important;
}
.content1-wrapper .content1-text .content1-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content1-wrapper .content1-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    height: 600px;
  }
  .content1-wrapper .content1-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content1-wrapper .content1-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content1-wrapper .content1-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content1-wrapper .content1-text .content1-content,
  .content1-wrapper .content1-text .content1-title {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text .content1-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.content10-wrapper {
  height: 300px;
  width: 80%;
  top: 30px;
  text-align: center;
  margin-left: 10%;
  margin-bottom: 50px;
  background: url(https://api.highwho.com/static/homepage/img/map.jpg) no-repeat 50%;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content10-wrapper .icon-wrapper {
  text-align: center;
  position: relative;
  cursor: pointer;
}
.content10-wrapper .icon-wrapper img {
  display: block;
}
.content10-wrapper .icon {
  position: relative;
  z-index: 1;
  animation: BeatAnim 2s ease-in-out infinite;
}
.content10-wrapper .icon-shadow {
  display: inline-block;
  position: relative;
  top: -12px;
  z-index: 0;
  animation: ScaleAnim 2s ease-in-out infinite;
  transform-origin: 50%;
}
.content10-wrapper .map-tip {
  position: absolute;
  width: 330px;
  background: #fff;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(13, 26, 38, 0.12);
  left: 50%;
  top: 50%;
  margin-left: 30px;
  margin-top: -60px;
  font-size: 14px;
  z-index: 10;
  transform-origin: 0 50%;
  text-align: left;
}
.content10-wrapper .map-tip h2 {
  font-size: 16px;
  color: #0d1a26;
  margin-bottom: 8px;
}
@media screen and (max-width: 767px) {
  .content10-wrapper {
    padding-bottom: 0;
  }
}
@keyframes BeatAnim {
  0%,
  25%,
  35%,
  45% {
    transform: translateY(0);
  }
  15% {
    transform: translateY(-30px);
  }
  30% {
    transform: translateY(-15px);
  }
  40% {
    transform: translateY(-7px);
  }
}
@keyframes ScaleAnim {
  0%,
  25%,
  35%,
  45% {
    transform: scale(1);
  }
  15% {
    transform: scale(0.5);
  }
  30% {
    transform: scale(0.7);
  }
  40% {
    transform: scale(0.9);
  }
}
